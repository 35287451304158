import Img1 from '../Images/Events/Diwali.jpg';
import Img2 from '../Images/Events/ganpati.jpg';
import Img3 from '../Images/Events/holi celebration.jpg';
import Img4 from '../Images/Events/tree plantation.jpg';
import Img5 from '../Images/Events/rakshabandhan.JPG';
import Img6 from '../Images/Events/chrismas.jpg';
import Img7 from '../Images/Events/republic day.JPG';
import Img8 from '../Images/Events/beach clean up .jpg';
import Img9 from '../Images/Events/stationard donat.jpg';
import Img10 from '../Images/Events/blood donation.jpg';
import Img11 from '../Images/Events/flood donation.jpeg';
import Img12 from '../Images/Events/orhanage.jpg';
import Img13 from '../Images/Events/martyrs day.jpg';
import Img14 from '../Images/Events/street-play.JPG';
import Img15 from '../Images/Events/NSS.jpg';

const EventsData = [
    {
        title:"DIWALI CELEBRATION",
        date: "12-1-23",
        info: "Description",
        img: Img1
    },{
        title:"GANPATI CELEBRATION",
        date: "2-1-23",
        info: "Description",
        img: Img2
    },{
        title:"HOLI CELEBRATION",
        date: "2-1-23",
        info: "Description",
        img: Img3
    },{
        title:"TREE-PLANTATION DRIVE",
        date: "2-1-23",
        info: "Description",
        img: Img4
    },{
        title:"RAKSHABANDHAN CELEBRATION",
        date: "2-1-23",
        info: "Description",
        img: Img5
    },{
        title:"CHRISTMAS CELEBRATION",
        date: "2-1-23",
        info: "Description",
        img: Img6
    },{
        title:"REPUBLIC DAY CELEBRATION",
        date: "2-1-23",
        info: "Description",
        img: Img7
    },{
        title:"CLEAN-UP DRIVE",
        date: "2-1-23",
        info: "Description",
        img: Img8
    },{
        title:"STATIONARY DONATION DRIVE",
        date: "2-1-23",
        info: "Description",
        img: Img9
    },{
        title:"BLOOD DONATION DRIVE",
        date: "2-1-23",
        info: "Description",
        img: Img10
    },{
        title:"FLOOD RELIEF DONATION DRIVE",
        date: "2-1-23",
        info: "Description",
        img: Img11
    },{
        title:"ORPHANAGE VISIT",
        date: "2-1-23",
        info: "Description",
        img: Img12
    },{
        title:"MARTYR'S DAY",
        date: "2-1-23",
        info: "Description",
        img: Img13
    },{
        title:"AWARENESS CAMPAIGNS",
        date: "2-1-23",
        info: "Description",
        img: Img14
    },{
        title:"NSS CAMP",
        date: "2-1-23",
        info: "Description",
        img: Img15
    },
];

export default EventsData;