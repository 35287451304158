import React from "react";
import '../Styles/Teams.css'
import team01 from '../Images/teams/Sharad Jaghav.jpg'
import team02 from '../Images/teams/nitin-sirps-removebg-preview.jpg'
import team03 from '../Images/teams/mansi-maam1-removebg-preview.jpg'
import team04 from '../Images/teams/Sujit_President.png'
import team05 from '../Images/teams/Smit_VP.png'
import team06 from '../Images/teams/Aditi_GS.png'
import team07 from '../Images/teams/Rohit_Treasurer.png'
import team08 from '../Images/teams/Atharva_Admin.png'
import team09 from '../Images/teams/Sakshi_CNE.png'
import team10 from '../Images/teams/raj_ec.jpg'
import team11 from '../Images/teams/atharva_ec.jpg'
import team12 from '../Images/teams/nikhil_market.jpg'
import team13 from '../Images/teams/raj_market.jpg'
import team14 from '../Images/teams/amey_market.jpg'
import team15 from '../Images/teams/ishwari_pro.jpg'
import team16 from '../Images/teams/kaarthik_pro.jpg'
import team17 from '../Images/teams/om_dmh.jpg'
import team18 from '../Images/teams/lamiya_dmh.jpg'
import team19 from '../Images/teams/shreyas_dmh.jpg'
import team20 from '../Images/teams/ahana_oc.jpg'
import team21 from '../Images/teams/sahil_logi.jpg'
import team22 from '../Images/teams/vikas_logi.jpg'
import team23 from '../Images/teams/arya_logi.jpg'
import team24 from '../Images/teams/rajan_logi.jpg'
import team25 from '../Images/teams/shakti_design.jpg'
import team26 from '../Images/teams/pranav_design.jpg'
import team27 from '../Images/teams/gunjan_design.jpg'
import team28 from '../Images/teams/khushi_design.jpg'
import team29 from '../Images/teams/anik_eic.jpg'
import team30 from '../Images/teams/shubh_eic.jpg'
import team31 from '../Images/teams/danish_cto.jpg'
import team32 from '../Images/teams/dhumra_cto.jpg'
import team33 from '../Images/teams/anushka_creative.jpg'
import team34 from '../Images/teams/aditi_creativ.jpg'
import team35 from '../Images/teams/neha_creativ.jpg'
import team36 from '../Images/teams/pratham_dbh.jpg'
import team37 from '../Images/teams/gaurav_dbh.jpg'
import team38 from '../Images/teams/aarush_sponser.jpg'
import team39 from '../Images/teams/arjun_sponsor.jpg'

const faculty = [
  {
      imgUrl: team01,
      name: 'Dr. Sharad P. Jadhav',
      position1: 'Faculty Coordinator',
      position2: 'Social Wing RAIT'
  },

  {
      imgUrl: team02,
      name: 'Mr. Nitin Chavan',
      position1: 'Program Officer',
      position2: 'NSS RAIT'
  },

  {
      imgUrl: team03,
      name: 'Ms. Mansi Jawale',
      position1: 'Program Officer',
      position2: 'NSS RAIT'
  },
  
  
]

const core1 = [
    {
        imgUrl: team04,
        name: 'Sujit Dalvi',
        position1: 'President',
    },
  
    {
        imgUrl: team05,
        name: 'Smit Mhatre',
        position1: 'Vice President',
    },
  
    {
        imgUrl: team06,
        name: 'Aditi Patil',
        position1: 'General Secretary'
    },

    {
        imgUrl: team07,
        name: 'Rohit Shetty',
        position1: 'Treasurer'
    },
    
    
  ]

  const core2 = [
    {
        imgUrl: team08,
        name: 'Atharva Sankhe',
        position1: 'Administrator',
    },
  
    {
        imgUrl: team09,
        name: 'Sakshi Kinikar',
        position1: 'Chief NSS Executive',
    },
    
  ]

  const jointCore1 = [
    {
        imgUrl: team10,
        name: 'Raj Kurlekar',
        position1: 'Event Co-ordinator',
    },
  
    {
        imgUrl: team11,
        name: 'Atharva Mane',
        position1: 'Event Co-ordinator',
    },
  
    {
        imgUrl: team12,
        name: 'Nikhil Goswami',
        position1: 'Marketing Head',
    },
    
    {
        imgUrl: team13,
        name: 'Raj Pawar',
        position1: 'Marketing Head',
    },
  ]

  const jointCore2 = [

    {
        imgUrl: team14,
        name: 'Amey Bhuvad',
        position1: 'Marketing Head',
    },
  
    {
        imgUrl: team15,
        name: 'Ishwari Mundake',
        position1: 'Public Relations Officer',
        position2: 'NSS RAIT'
    },
    
    {
        imgUrl: team16,
        name: 'Kaarthik Nalla',
        position1: 'Public Relations Officer',
    },

    {
        imgUrl: team17,
        name: 'Om Sankhe',
        position1: 'Digital Marketing Head',
    },
  ]

  const jointCore3 = [
  
    {
        imgUrl: team18,
        name: 'Lamiya Chasmawala',
        position1: 'Digital Marketing Head',
    },
    
    {
        imgUrl: team19,
        name: 'Shreyas Sindhur',
        position1: 'Digital Marketing Head',
    },

    {
        imgUrl: team20,
        name: 'Ahana Shaikh',
        position1: 'Orphanage Co-ordinator',
    },

    {
        imgUrl: team21,
        name: 'Sahil Naikwade',
        position1: 'Logistics Head',
    },

  ]

  const jointCore4 = [
    
    {
        imgUrl: team22,
        name: 'Vikas Sharma',
        position1: 'Logistics Head',
    },
  
    {
        imgUrl: team23,
        name: 'Arya Durne',
        position1: 'Logistics Head',
    },
  
    {
        imgUrl: team24,
        name: 'Rajan Shirsath',
        position1: 'Logistics Head',
    },

    {
        imgUrl: team25,
        name: 'Shakti Murugan',
        position1: 'Design Head',
    },
  ]

  const jointCore5 = [
    
    {
        imgUrl: team26,
        name: 'Pranav S Kumar',
        position1: 'Design Head',
    },
  
    {
        imgUrl: team27,
        name: 'Gunjankumar Choudhari',
        position1: 'Design Head',
    },

    {
        imgUrl: team28,
        name: 'Khushi Tamhankar',
        position1: 'Design Head',
    },
    
    {
        imgUrl: team29,
        name: 'Anik Bhosle',
        position1: 'Editor in Chief',
    },

  ]

  const jointCore6 = [
  
    {
        imgUrl: team30,
        name: 'Shubh Kulkarni',
        position1: 'Editor in Chief',
    },

    {
        imgUrl: team31,
        name: 'Danish Ansari',
        position1: 'Chief Technical Officer',
    },

    {
        imgUrl: team32,
        name: 'Dhumravarna Ambre',
        position1: 'Chief Technical Officer',
    },
    
    {
        imgUrl: team33,
        name: 'Anushka Johri',
        position1: 'Creative Head',
    },

  ]

  const jointCore7 = [

    {
        imgUrl: team34,
        name: 'Aditi Salunkhe',
        position1: 'Creative Head',
    },

    {
        imgUrl: team35,
        name: 'Neha Bandarkar',
        position1: 'Creative Head',
    },

    {
        imgUrl: team36,
        name: 'Pratham Shetty',
        position1: 'Database Head',
    },

    {
        imgUrl: team37,
        name: 'Gaurav Mishra',
        position1: 'Database Head',
    },

  ]

  const jointCore8 = [

    {
        imgUrl: team38,
        name: 'Aarush Mandal',
        position1: 'Sponsorship Head',
    },

    {
        imgUrl: team39,
        name: 'Arjun Sharma',
        position1: 'Sponsorship Head',
    },

  ]

const Team = () => {
  return (
    <>
    
        <section className='our__team'>
        <div className='container'>
            <div className='team__content'>
                <h2 className='subtitle'>Faculties</h2>
                {/* <h2>
                    Join With <span className='highlight'>Our Team</span>
                </h2> */}
            </div>
            <div className='team__wrapper1'>
                {
                    faculty.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>
                                <p className='description'>{item.position2}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>

                    ))
                }
            </div>
            
        </div>
    </section>
    
    {/* Core Team */}

    <section className='our__team'>
        <div className='container'>
            <div className='team__content'>
                <h2 className='subtitle'>Core</h2>
                {/* <h2>
                    Join With <span className='highlight'>Our Team</span>
                </h2> */}
            </div>
            <div className='team__wrapper'>
                {
                    core1.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
                
            <div className='team__wrapper'>
                {
                    core2.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            
        </div>
    </section>

    {/* Joint Core Team */}

    <section className='our__team'>
        <div className='container'>
            <div className='team__content'>
                <h2 className='subtitle'>Joint Core</h2>
                {/* <h2>
                    Join With <span className='highlight'>Our Team</span>
                </h2> */}
            </div>
            <div className='team__wrapper'>
                {
                    jointCore1.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
                
            <div className='team__wrapper'>
                {
                    jointCore2.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='team__wrapper'>
                {
                    jointCore3.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='team__wrapper'>
                {
                    jointCore4.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='team__wrapper'>
                {
                    jointCore5.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='team__wrapper'>
                {
                    jointCore6.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='team__wrapper'>
                {
                    jointCore7.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='team__wrapper'>
                {
                    jointCore8.map((item, index) => (
                        <div className='team__item' key={index}>
                            <div className='team__img'>
                                <img loading="lazy" src={item.imgUrl} alt='' />
                            </div>
                            <div className='team__details'>
                                <h4>{item.name}</h4>
                                <p className='description'>{item.position1}</p>

                                <div className='team__member-social'>
                                    <span><i class='ri-linkedin-line'></i></span>
                                    <span><i class='ri-twitter-line'></i></span>
                                    <span><i class='ri-facebook-line'></i></span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            
        </div>
    </section>
    </>
  );
};
export default Team;
